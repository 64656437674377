import styled, { css } from 'styled-components';
import { AddCalenderButton } from '../MainEvents/styles';

const RegularFont = 'Rubik-Regular';

const CommentsContainer = styled.div`
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  width: 100%;
  font-family: ${RegularFont};
  
  > span {
    font-size: 24px;
    letter-spacing: 0px;
    color: rgb(0,0,0);
  }
`;

const CommentContent = styled.div`
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  width: 100%;
  margin-top: 25px;
  
  ${(props) => props.isReply && css`
    padding: 0 0 0 70px;
    margin-top: 15px;

    @media (max-width: 500px) {
      padding: 0 0 0 50px;
    }
  `}
`;

const ImageWrapper = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 20px;
  float: left;

  @media (max-width: 500px) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

  > img {
    width: 100%;
    height: 100%;
    float: left;
    border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : '3px'};
  }
`;

const CommentSection = styled.div`
  display: inline-block;
  background-color: ${({ user }) => user ? 'rgba(105, 194, 255, 0.1)' : 'rgba(156, 156, 156, 0.1)'};

  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  width: calc(100% - 70px);
  padding: 15px;
  border-radius: 0px 6px 6px 6px;
  white-space: pre-line;
  > img{
    width: 300px;
  }
  span {
    font-size: 16px;
    letter-spacing: 0px;
    @media (max-width: 500px) {
      font-size: 12px;
    }
    pointer-events: none;
  }
  
  span:first-child {
    font-family: "rubik-medium";
    text-transform: capitalize;
    span {
      letter-spacing: 0px;
      color: rgb(21,159,202);
      font-family: Rubik-Bold;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      pointer-events: auto;
      cursor: pointer;
    }
  }
  
  > div {
    display: inline-block;
    color: #159fc9;
    margin-top: -3px;
    
    @media (max-width: 500px) {
      margin-top: -5px;
    }
  }

  .CommentDescription {
    display: block;
    font-size: 16px;
    width: 100%;
    word-break: break-word;
    text-transform: none !important;
    font-family: ${RegularFont} !important;
    text-align: justify;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }

  .CommentSpan {
    display: block;
  }
  
  ${({ isReply }) => isReply && css`
    padding: 10px;
  `}
  
  @media (max-width: 500px) {
    width: calc(100% - 50px);
    padding: 10px;
  }
`;

const StyledLink = styled.a`
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 17px;

  color: #0D4270;
  font-size: 14px;
  font-family: 'Rubik-medium';
  margin-left:70px;
  >div{
    width: auto;
    cursor: pointer;
    display: inline-block;
    line-height: 17px;
    color: #0D4270;
    font-size: 14px;
    padding: 0 5px;
    font-family: 'Rubik';
  }

  > img {
    width: 18px;
    margin-right: 5px;
  }
  
  ${({isReply}) => isReply && css`
    margin-left: 0;
  `}
  
  &:focus, &:hover {
    outline: none;
    text-decoration: none;
  }
  
  @media (max-width: 500px) {
    margin-left: 50px;
    font-size: 12px;
  }
`;

const TimeContent = styled.span`
  font-size: 12px;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  text-align: right;
  margin-top: 10px;

  display: inline-block;

  width: ${({ fullWidth }) => fullWidth ? '100%' : 'calc(100% - 112px)'};
  
  ${({ isReply }) => isReply && css`
    width: 100%;
    margin-right: 5px;
    display: inline-block;
    margin-left:0
  `}
  
  @media (max-width: 500px) {
    width: ${({ fullWidth }) => fullWidth ? '100%' : 'calc(100% - 82px)'};
    font-size: 10px;

    ${({ isReply }) => isReply && css`
      width: 100%;
    `}
  }
`;

const ViewMoreLink = styled.a`
  font-size: 14px;
  color: #159fc9;
  margin-top: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  display: inline-block;
  
  ${({ isReply }) => isReply && css`
    padding-left: 70px;
    
    @media (max-width: 500px) {
      padding-left: 0;
    }
  `}
  
  &:focus, &:hover {
    outline: none;
    color: #159fc9;
    text-decoration: none;
  }
  
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const TextFieldContainer = styled.div`
  padding-bottom: 10px;
  float: right;
 padding-left:70px;
width:100%;
  @media (max-width: 500px) {
    padding: 0;
 padding-left:50px;

  }
`;

const SingleComment = styled.div`
  width: 100%;
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};

  
`;

const ButtonContainer = styled.div`
  text-align: right;
  width: 100%;
`;

const PostCommentButton = styled(AddCalenderButton)`
  margin-top: 15px;
  margin-left:70px;
  float: left;
  height: 40px;
  width: 103px;
  text-transform: none;
  padding: 0;
  background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
border-radius: 6px;
font-family: 'Rubik-medium';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
/* identical to box height, or 133% */
color: #FFFFFF;
>img{
  margin-left:10px;
}
`;
const InputFieldContainer = styled.div`
  width: ${({UpdateStatus, survey}) => UpdateStatus || survey ? '100%' : 'calc(100% - 70px)'};
  float: left;
  background-color: white;
  -moz-box-shadow: ${({UpdateStatus}) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none' };
  -webkit-box-shadow: ${({UpdateStatus}) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none' };
  box-shadow: ${({UpdateStatus}) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none' };
  height: 'auto';
  border-color: ${({comment, survey}) => comment ? 'rgba(0, 0, 0, 0.09)' : survey ? '#cdcbcb': 'none'};
  border-image: ${({comment}) => comment ? 'initial' : 'none'};
  padding: ${({comment}) => comment ? '0px' : '15px'};
  padding-bottom: ${({comment}) => comment && "0px"};
  padding-left: ${({comment}) => comment && "5px"};
  border-radius: ${({comment}) => comment ? '8px' : '4px'};
  border-width: ${({comment, survey}) => comment || survey ? '1px' : '0px'};
  border-style: ${({comment, survey}) => comment || survey ? 'solid' : 'none'};
  @media (max-width: 500px) {
    width: ${({UpdateStatus}) => UpdateStatus ? '100%' : 'calc(100% - 50px)'};
  }
  .CustomTextAreaField {
    float: left;
    background-color: #ffffff;
    border: none;
    width: 80%;
    height: 60px;
    resize: auto;
    padding: 15px;
    padding-top: 20px;
    resize: none;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    // line-height: 18px;
    // font-size: 14px;
    border-radius: 4px;
    // border: none;
    // width: calc(100% - 25px);
    // color: rgb(153,153,153);
    // resize: none;
    // float: left;
    // height: 100%;
    // position: absolute;
    z-index: 2;
    // background-color: transparent;
    overflow: auto;
    transition: transform 1s;
    // padding: 0;
    // letter-spacing: 1px;
    
    &:focus, &:active {
      outline: none;
    }
      
    ::placeholder {
      color: rgb(153,153,153);
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: rgb(153,153,153);
    }

    ::-ms-input-placeholder {
      color: rgb(153,153,153);
    }
  }
`;
const FieldContainer = styled.div`
  padding: ${({ commentsSection }) => commentsSection ? '15px 15px 20px 15px' : '0 25px 10px 25px'};
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
  width: 100%;
  background: white;
  margin-bottom:10px;
  padding-bottom:25px;
  position: relative; 
  ${({ noFloat }) => noFloat && css`
    padding: 34px 0 20px 0;
    @media (max-width: 500px) {
      padding: 0;
    }
  `}
  
  > span {
    font-size: 24px;
  }
  
  &:last-child {
    border-bottom: none;
  }
  
  ${(props) => props.fixHeight && css`
    border-bottom: none;
  `}

  ${({ socialPage }) => socialPage && css`
    > span {
      font-size: 18px;
      float: left;
      padding: 20px 0 10px;
    }
  `}

  @media (max-width: 500px) {
    padding: 10px;
  }
`;
const ShoutoutImage = styled.div`
  display: inline;
  > img {
    width: ${({comment}) => comment ? '16px' : '24px'};
    height: ${({comment}) => comment ? '16px' : '24px'};
    margin-left: 7px;
  }
`;

export { FieldContainer,ShoutoutImage, CommentsContainer,InputFieldContainer, CommentContent, CommentSection, StyledLink, TimeContent, ViewMoreLink,
  TextFieldContainer, SingleComment, ButtonContainer, PostCommentButton, ImageWrapper };