/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import _ from 'lodash';

import { connect } from 'react-redux';
import { CommentContent, CommentSection, CommentsContainer, SingleComment, ImageWrapper, StyledLink, TimeContent, ViewMoreLink, TextFieldContainer, InputFieldContainer, PostCommentButton, ButtonContainer, ShoutoutImage } from './behaviourtStyles';
import { FieldContainer } from '../EventDetails/styles';
import Image from '../Image';
import { applyHighlights, convertDateInTimezone, convertMonthFromDate, convertUnicode, getDecodedString } from '../../utils/methods';
import { getUserName, getUserProfileImage, getUserId, getUserPosts } from '../../redux/actions';
import { ImageUrl } from '../../utils/constants';
import SocialFeedTextareaNew from '../SocialFeeds/SocialFeedTextareaNew';
import { withTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

class BehaviorChallengeComments extends Component {
  constructor() {
    super();
    this.state = {
      isUpdatedImage: false,
      mentionedUsers: [],
      users: [],
      userIdArray: [],
      taggedUser: '',
      replyCount: 3,
      replyTextField: '',
      replyText: '',
      shoutoutImage: null,
      imgsrcV2: null
    }
  }

  componentDidMount() {
    if (!this.props.userName) this.props.fetchUserName();
    if (!this.props.profileImage) this.props.fetchUserProfileImage();
    if (!this.props.userId) this.props.fetchUserId();
    this.checkBase64(this.props.profileImage);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage)
    }
  }

  createSafeHTML = (htmlString) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
    const range = document.createRange();
    const fragment = range.createContextualFragment(sanitizedHTML);
    return fragment; 
  };

  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          isUpdatedImage: true
        })
      }
      else {
        this.setState({
          isUpdatedImage: false
        })
      }
    }
  }
 

  onChangeValue = (e) => {
    this.setState({
      replyText: e
    });
  };

  toCodePoints = function (string) {
    let chars = "";
    for (let i = 0; i < string.length; i++) {
      let c1 = string.charCodeAt(i);
      let b1 = string.charAt(i);
      if (c1 >= 0xD800 && c1 < 0xDC00 && i + 1 < string.length) {
        let c2 = string.charCodeAt(i + 1);
        if (c2 >= 0xDC00 && c2 < 0xE000) {
          chars = chars + ("&#" + ((+0x10000 + ((c1 - 0xD800) << 10) + (c2 - 0xDC00))) + ";");
          i++;
          continue;
        }
      }
      else {
        chars = chars + b1;
      }
    }
    return chars;
  }

  addReply = (e, commentId) => {
    e.preventDefault();
    const { users, userIdArray, replyText, taggedUser, mentionedUsers, exemplifyID } = this.state;
    const { postReply, id } = this.props;

    let shoutout = {
      exemplifies: mentionedUsers
    }

    let reply = this.toCodePoints(replyText) , shoutOutUserName;
    if (!_.isEmpty(mentionedUsers)) {
      shoutOutUserName = `${applyHighlights(users, userIdArray, taggedUser)} - ${this.state.shoutoutText}`;
    }
    let exemplify_id = exemplifyID;
    let image = "";
    postReply(commentId, reply, id, shoutout, shoutOutUserName, exemplify_id, image);

    this.setState({
      showTextAreaFor: null,
      reply: '',
      showGivenShoutout: false,
      shoutoutImage: null,
      shoutoutText: null,
      thankYou: [],
      congrats: [],
      jobWellDone: [],
      taggedUser: '',
      userIdArray: [],
      users: []
    });
  };

  showTextField = (id) => {
    this.setState({
      replyTextField: id
    })
  };

  showMoreReplies = (replyCount) => {
    this.setState({
      replyCount
    });
  };

  updateText = (text, userId, taggedUserName, bool) => {
    const { userIdArray, users, taggedUser } = this.state;
    let userIdArr = [...userIdArray];
    let userNameArr = [...users];
    let taggedUsers = taggedUser;
    if (userIdArr.indexOf(userId) === -1) {
      userIdArr.push(userId);
      userNameArr.push(taggedUserName);
      if (!_.isEmpty(taggedUsers))
        taggedUsers = `${taggedUsers}, ${taggedUserName}`;
      else
        taggedUsers = taggedUserName;
    }
    this.setState({
      replyText: text,
      userIdArray: userIdArr,
      users: userNameArr,
      taggedUser: taggedUsers,
      showGivenShoutout: bool
    });
  };

  updateImage = (img) => {

    this.setState({
      imgsrcV2: img
    })
  }

  updateShoutoutArray = (mentionedUsers, text, image, exemplifies_id) => {
    this.setState({
      showGivenShoutout: true,
      shoutoutText: text,
      shoutoutImage: image,
      shoutoutType: exemplifies_id ? exemplifies_id : null,
      mentionedUsers: mentionedUsers,
      exemplifyID: exemplifies_id ? exemplifies_id : null
    });
  };

  onRemoveShoutout = (array) => {
    this.setState({
      [array]: [],
      showGivenShoutout: false,
      taggedUser: '',
      shoutoutImage: null,
      shoutoutText: null,
      userIdArray: [],
      users: [],
      shoutoutType: 0,
      mentionedUsers: []
    });
  };

  onRemoveUser = () => {
    this.setState({
      taggedUser: '',
      showGivenShoutout: false,
      userIdArray: [],
      shoutoutImage: null,
      shoutoutText: null,
      users: []
    });
  };

  togglePostButton = (value) => {
    const { shoutoutText, userIdArray } = this.state;
    if (userIdArray.length !== 0) {
      if (userIdArray.length !== 0 && !_.isNull(shoutoutText)) {
        return false;
      } else
        return true;
    } else if (value && value.trim() !== '') {
      return false;
    }
    return true;
  };


  showProfileImage = (comment, isOwn) => {
    const { userId, profileImage } = this.props;
    const { isUpdatedImage } = this.state;
    if (isOwn) {
      if (isUpdatedImage) {
        return (<img src={profileImage} style={{borderRadius:'50%'}}/>)
      }
      else {
        return (<Image image={profileImage} style={{borderRadius:'50%'}}/>)
      }
    } else if (userId === comment.uid) {
      if (isUpdatedImage) {
        return (<img src={profileImage} alt="avatar" style={{borderRadius:'50%'}}/>)
      }
      else {
        return (<Image image={profileImage} alt="avatar" style={{borderRadius:'50%'}}/>)
      }
    }
    else {
      return (<Image image={comment.profile_image} alt="avatar" style={{borderRadius:'50%'}}/>)
    }
  };

  getTaggedUserId = (id) => {
    const { history, userId } = this.props;
    if (id) {
      if (userId === id) {
        history.push('/profile');
      } else {
        history.push(`/profile/${id}`);
      }
    }
  };
  // showTextField = (id) => {
  //   this.setState({
  //     replyTextField: id
  //   })
  // };
  renderTextArea = (value, id) => {
    const { noFloat, enableTrigger } = this.props;
    return (
      <TextFieldContainer noFloat={noFloat} style={{marginBottom:"15px"}}>
        <CommentContent noFloat={noFloat}>
          <ImageWrapper>
            {this.showProfileImage('', true)}
          </ImageWrapper>
          <InputFieldContainer comment style={{border:"none"}}>
            <SocialFeedTextareaNew
              userCompany={this.props.userCompany}
              addInspiration={this.onChangeValue}
              inspirationQuote={value}
              updateText={this.updateText}
              users={this.state.users}
              userIdArray={this.state.userIdArray}
              placeholder={this.props.t("Write your Reply here...")}
              showGivenShoutout={this.state.showGivenShoutout}
              updateShoutoutArray={this.updateShoutoutArray}
              emptyShoutoutArray={this.emptyShoutoutArray}
              onRemoveShoutout={this.onRemoveShoutout}
              isCommentOrReply={"isCommentOrReply"}
              shoutoutText={this.state.shoutoutText}
              shoutoutImage={this.state.shoutoutImage}
              taggedUser={this.state.taggedUser}
              onRemoveUser={this.onRemoveUser}
              enableTrigger={enableTrigger}
              // updateImage={this.updateImage}
            />
          </InputFieldContainer>
        </CommentContent>
        {this.state.replyText.length > 0 && <ButtonContainer noFloat={noFloat}>
         
          <PostCommentButton color="#159fc9" style={{background:"#005c87"}} onClick={(e) => this.addReply(e, id)} disabled={this.togglePostButton(value)}>{this.props.t("Reply")}<img src='/public/images/SendPost.svg' /></PostCommentButton>
        </ButtonContainer>}
      </TextFieldContainer>
    );
  };

  showComment(comment) {
    const cleanHTML = DOMPurify.sanitize(this.createSafeHTML(getDecodedString(comment).split('\\n').join('<br />')));
    return (
      <span className="CommentDescription">{cleanHTML}</span>
    );
    // return (<span className="CommentDescription" dangerouslySetInnerHTML={{ __html: getDecodedString(comment).split('\\n').join('<br />') }} />)
  }
  // showMoreReplies = (replyCount) => {
  //   this.setState({
  //     replyCount
  //   });
  // };

  showImage(image) {
    return (<img src={`${ImageUrl}/${image}`} />)
  }

  render() {
    const { comments, fixHeight, noFloat, userId } = this.props;
    const { replyCount,replyText,replyTextField } = this.state;
    return (
      <CommentsContainer noFloat={noFloat}>
        <FieldContainer fixHeight={fixHeight} commentsSection noFloat={noFloat}>
          {
            comments.map((comment, index) => {
              const TimeOfComment = convertMonthFromDate(moment(convertDateInTimezone(comment.created_at)._d).format('hh:mma, MMMM DD'), this.props);
              const chunkOfReply = (!_.isEmpty(comment.replies) ? comment.replies.slice(0, replyCount) : []);

              return (
                <SingleComment key={index} noFloat={comment.uid !== userId ? 0 : 1}>

                  <CommentContent noFloat={comment.uid !== userId ? 0 : 1}>
                    <ImageWrapper noFloat={comment.uid !== userId ? 0 : 1} onClick={() => this.getTaggedUserId(comment.uid)} borderRadius={"3px"}>
                      {this.showProfileImage(comment, false)}
                    </ImageWrapper>
                    <CommentSection noFloat={comment.uid !== userId ? 0 : 1} user={comment.uid == userId} style={{background: comment.uid == userId ? "#f0f6f8" : "#f7f5eb", color:"#005c87"}}>
                      <span className="CommentSpan">{comment.uid === userId ? 'You' : comment.fullname}&nbsp;</span>

                      {comment && comment.image ? [this.showImage(comment.image), this.showComment(comment.comment)] : this.showComment(comment.comment)}
                    </CommentSection>
                    <StyledLink noFloat={noFloat} onClick={() => this.showTextField(comment.id)} style={{color:"#005c87",marginBottom:"15px"}}>{this.props.t("Reply")}</StyledLink>
                    <TimeContent style={{color:"#669db7"}}>{TimeOfComment}</TimeContent>
                    {replyTextField === comment.id && this.renderTextArea(replyText, comment.id)}
                    {
                      chunkOfReply.map((reply, index) => {
                        const TimeOfReply = convertMonthFromDate(moment(convertDateInTimezone(reply.created_at)._d).format('hh:mma, MMMM DD'), this.props);
                        return (
                          <CommentContent key={reply.reply + index} isReply noFloat={noFloat}>
                            <ImageWrapper style={{borderRadius:'50%'}}>
                              {this.showProfileImage(reply, false)}
                            </ImageWrapper>

                            <CommentSection user={reply.uid === userId} isReply noFloat={noFloat} style={{background: reply.uid == userId ? "#f0f6f8" : "#f7f5eb"}}>
                              <span style={{color:"#005c87"}}>{reply.uid === userId ? this.props.t('You') : reply.fullname}&nbsp;</span>
                              {/*<div>.</div>*/}
                              {!this.props.isChallengeOrEvent ?
                                <span onClick={(e) => this.getTaggedUserId(e)}>&nbsp;
                                  {!_.isNull(reply.shoutout_user_name) ?
                                    // <span dangerouslySetInnerHTML={{ __html: convertUnicode(reply.shoutout_user_name) }} /> : null
                                    <span>{DOMPurify.sanitize(this.createSafeHTML(convertUnicode(reply.shoutout_user_name)))}</span> : null
                                  }
                                  {reply.recognition_image !== null ?
                                    <ShoutoutImage comment>
                                      <Image image={reply.recognition_image} />
                                    </ShoutoutImage> : null
                                  }
                                  {/* <span className="CommentDescription" style={{color:"#005c87"}} dangerouslySetInnerHTML={{ __html: getDecodedString(reply.reply).split('\\n').join('<br />') }} /> */}
                                  <span className="CommentDescription" style={{color:"#005c87"}}>{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(reply.reply).split('\\n').join('<br />')))}</span>
                                </span> :
                                // <span className="CommentDescription" style={{color:"#005c87"}} dangerouslySetInnerHTML={{ __html: getDecodedString(reply.reply).split('\\n').join('<br />') }} />
                                <span className="CommentDescription" style={{color:"#005c87"}}>{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(reply.reply).split('\\n').join('<br />')))}</span>
                              }
                            </CommentSection>
                            <TimeContent isReply style={{color:"rgb(102, 157, 183)"}}>{TimeOfReply}</TimeContent>
                          </CommentContent>
                        )
                      })
                    }
                    {
                      (!_.isEmpty(comment.replies) && comment.replies.length > replyCount) &&
                      <ViewMoreLink isReply onClick={() => this.showMoreReplies(comment.replies.length)} style={{color:"#005c87",background:"#f2f7f9"}}>
                        {this.props.t("View More Replies")}
                      </ViewMoreLink>
                    }

                  </CommentContent>
                </SingleComment>)
            })
          }
        </FieldContainer>
      </CommentsContainer>
    )
  }
}

BehaviorChallengeComments.propTypes = {
  comments: PropTypes.array.isRequired,
  fixHeight: PropTypes.bool,
  postReply: PropTypes.func,
  id: PropTypes.number,
  replySuccess: PropTypes.string,
  noFloat: PropTypes.bool,
  fetchUserName: PropTypes.func.isRequired,
  userName: PropTypes.string,
  fetchUserProfileImage: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
  fetchUserId: PropTypes.func.isRequired,
  userId: PropTypes.number,
  fetchUserPosts: PropTypes.func.isRequired,
  history: PropTypes.object,
  userCompany: PropTypes.object,
  isChallengeOrEvent: PropTypes.bool,
  enableTrigger: PropTypes.bool,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  userName: state.profileData.userName,
  profileImage: state.profileData.profileImage,
  userId: state.profileData.userId,
  userCompany: state.profileData.userCompany,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserName: () => dispatch(getUserName()),
  fetchUserProfileImage: () => dispatch(getUserProfileImage()),
  fetchUserId: () => dispatch(getUserId()),
  fetchUserPosts: (id, history) => dispatch(getUserPosts(id, history))
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(BehaviorChallengeComments));
